import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { Actions } from './pages/submissions-page/common/types/Actions';
import { ErrorBoundary } from './common/ErrorBoundary';

const AccountMapPage = lazy(
  () => import('./pages/account-map-page/AccountMapPage'),
);
const AdminTab = lazy(() => import('./pages/settings-page/AdminTab'));
const BookingDetailsPage = lazy(() => import('./pages/booking-details-page'));
const CalculationDetailsPage = lazy(
  () => import('./pages/calculation-details-page/CalculationDetailsPage'),
);
const CreateBookingPage = lazy(() => import('./pages/create-booking-page'));
const EditorTab = lazy(() => import('./pages/settings-page/EditorTab'));
const ExecutionTab = lazy(
  () => import('./pages/rules-page/rule-page/ExecutionDetailsTab'),
);
const ExecutionDetailsTab = lazy(
  () => import('./pages/rule-details-page/tabs/ExecutionDetailsTab'),
);
const ExportsPage = lazy(() => import('./pages/exports-page/ExportsPage'));
const FinalApproverTab = lazy(
  () => import('./pages/settings-page/FinalApproverTab'),
);
const GeneralLedger = lazy(
  () => import('./pages/general-ledger-page/GeneralLedger'),
);
const GeneralLedgerPage = lazy(() => import('./pages/general-ledger-page'));
const LandingPage = lazy(() => import('./pages/landing-page/LandingPage'));
const LocationMapPage = lazy(
  () => import('./pages/location-map-page/LocationMapPage'),
);
const ManageBookingsPage = lazy(
  () => import('./pages/manage-bookings-page/ManageBookingsPage'),
);
const ManageRulesPage = lazy(() => import('./pages/manage-rules-page'));
const PermissionDetailsTab = lazy(
  () => import('./pages/rule-details-page/tabs/PermissionDetailsTab'),
);
const PostToAcePage = lazy(
  () => import('./pages/post-to-ace-page/PostToAcePage'),
);
const PrivateRoute = lazy(() => import('./common/PrivateRoute'));
const RuleDetailsPage = lazy(() => import('./pages/rule-details-page'));
const RuleDetailsTab = lazy(
  () => import('./pages/rule-details-page/tabs/RuleDetailsTab'),
);
const RulePage = lazy(() => import('./pages/rules-page/rule-page/RulePage'));
const RulePageRouter = lazy(
  () => import('./pages/configure-rule-page/RulePageRouter'),
);
const ScheduleDetailsTab = lazy(
  () => import('./pages/rule-details-page/tabs/ScheduleDetailsTab'),
);
const SettingsPage = lazy(() => import('./pages/settings-page/SettingsPage'));
const SubmissionsPage = lazy(
  () => import('./pages/submissions-page/SubmissionsPage'),
);
const SubmissionsTab = lazy(
  () => import('./pages/submissions-page/tabs/SubmissionsTab'),
);
const UpdateBookingPage = lazy(() => import('./pages/update-booking-page'));
const VersionTab = lazy(
  () => import('./pages/rules-page/rule-page/VersionDetailsTab'),
);
const VersionDetailsTab = lazy(
  () => import('./pages/rule-details-page/tabs/VersionDetailsTab'),
);
const ViewPage = lazy(() => import('./pages/view-page/ViewPage'));

/**
 * App routes
 */
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <PrivateRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        children: [
          {
            path: 'admin',
            element: <AdminTab />,
          },
          {
            path: 'final-approver',
            element: <FinalApproverTab />,
          },
          {
            path: 'editor',
            element: <EditorTab />,
          },
        ],
      },
      {
        path: 'submission',
        element: <SubmissionsPage />,
        children: [
          {
            path: 'all-submissions',
            element: <SubmissionsTab action={Actions.ALL_SUBMISSIONS} />,
          },
        ],
      },
      {
        path: 'general/views',
        element: <ViewPage />,
      },
      {
        path: 'general/dataset-exports',
        element: <ExportsPage />,
      },
      {
        path: 'rules/:ruleId',
        element: <RuleDetailsPage />,
        children: [
          {
            index: true,
            element: <RuleDetailsTab />,
          },
          {
            path: 'execution-details',
            element: <ExecutionDetailsTab />,
          },
          {
            path: 'version-details',
            element: <VersionDetailsTab />,
          },
          {
            path: 'schedule-details',
            element: <ScheduleDetailsTab />,
          },
          {
            path: 'permission-details',
            element: <PermissionDetailsTab />,
          },
        ],
      },
      {
        path: 'rules',
        element: <RulePage />,
        children: [
          {
            path: 'execution-details/:ruleId',
            element: <ExecutionTab />,
          },
          {
            path: 'version-details/:ruleId',
            element: <VersionTab />,
          },
        ],
      },
      {
        path: 'submission/create-rule',
        children: [
          { index: true, element: <RulePageRouter /> },
          { path: ':ruleId', element: <RulePageRouter /> },
        ],
      },
      {
        path: 'submission/post-to-ace',
        element: <PostToAcePage />,
      },
      {
        path: 'general/manage-rules',
        element: <ManageRulesPage />,
      },
      {
        path: 'general',
        element: <GeneralLedgerPage />,
        children: [
          {
            path: 'ledger',
            element: <GeneralLedger />,
          },
        ],
      },
      {
        path: 'static',
        children: [
          { path: 'account', element: <AccountMapPage /> },
          { path: 'location', element: <LocationMapPage /> },
        ],
      },
      {
        path: 'bookings',
        children: [
          {
            path: 'create',
            element: <CreateBookingPage />,
          },
          { path: 'manage', element: <ManageBookingsPage /> },
          {
            path: ':bookingId',
            children: [
              {
                index: true,
                element: <BookingDetailsPage />,
              },
              {
                path: 'edit',
                element: <UpdateBookingPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'journal-entries/:jeId/calculation-details',
        element: <CalculationDetailsPage />,
      },
    ],
  },
];
